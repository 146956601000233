<template>
<div :class="classname">
    <label v-if="away" class="d-inline d-md-none">{{team.shortname}}</label>
    <label v-if="away" class="d-none d-md-inline">{{team.dispname}}</label>
    <b-img :src="flag(team)" :class="flagclass" />
    <label v-if="!away" class="d-inline d-md-none">{{team.shortname}}</label>
    <label v-if="!away" class="d-none d-md-inline">{{team.dispname}}</label>
</div>
</template>

<script>
export default {
    name: "HomeTeam",
    props: {
        "team": Object,
        away: Boolean,
        small: Boolean
    },
    computed: {
        flagclass() {
            return this.away ? "ml-1" : "mr-1";
        },
        classname(){
            var cname = "team";
            if(this.small)
                  cname += " team-small"

            return cname;
        }
    },
    methods: {
        flag() {
            return this.$images.teamFlag(this.team.flag);
        }
    }
};
</script>
